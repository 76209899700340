
myTest= function () {
   //alert('Welcome to custom js');
	
	val='<table cellpadding="0" cellspacing="0" style="border:1px solid #000;border-collapse:collapse;border-spacing:0px;"><tr><td>&nbsp;</td><td>M</td><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td><td>6</td><td>7</td><td>8</td><td>9</td><td>10</td><td>11</td><td>N</td><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td><td>6</td><td>7</td><td>8</td><td>9</td><td>10</td><td>11</td></tr>';
	for(i=0;i<4;i++)
	{
		val=val+'<tr>';
		if(i==0)
		{
			val=val+'<td >OFF</td>';
		}
		else if(i==1)
		{
			val=val+'<td >SB</td>';
		}
		else if(i==2)
		{
			val=val+'<td >D</td>';
		}		
		else if(i==3)
		{
			val=val+'<td style="line-height:20px;">ON</td>';
		}
		
		for(j=0;j<24;j++)
		{
			val=val+'<td style="border:1px solid #000;vertical-align: bottom;">';
			for(k=0;k<3;k++)
			{
				if(k==1)
				{
				val=val+'<div style="height:30px;border-right:1px solid #000;border-collapse:collapse;bottom:0px;float:left;margin-top:20px;">&nbsp;&nbsp;</div>';
				}
				else
				{
				val=val+'<div style="height:20px;border-right:1px solid #000;border-collapse:collapse;bottom:0px;float:left;display:table-cell;margin-top:30px;">&nbsp;&nbsp;</div>';
				}
			}
			val=val+'&nbsp;&nbsp;</td>';
		}
		val=val+'</tr>';
	}
	val=val+'</table>';
	document.getElementById("dt").innerHTML=val;

var chart = new CanvasJS.Chart("chartContainer", {
	animationEnabled: true,
	exportEnabled: true,
	title:{
		text: ""
	},
	axisY:{ 
		title: "Percentage",
		includeZero: false, 
		interval: .2,
		suffix: "%",
		valueFormatString: "#"
	},
	data: [{
		type: "stepLine",
		yValueFormatString: "#0.0\"%\"",
		xValueFormatString: "MMM YYYY",
		markerSize: 5,
		dataPoints: [
			{ x: new Date(2016, 0), y: 4.9 },
			{ x: new Date(2016, 1), y: 4.9 },
			{ x: new Date(2016, 2), y: 5.0 },
			{ x: new Date(2016, 3), y: 5.0, indexLabel: "Highest", indexLabelFontColor: "#C24642" },
			{ x: new Date(2016, 4), y: 4.7 },
			{ x: new Date(2016, 5), y: 4.9 },
			{ x: new Date(2016, 6), y: 4.9 },
			{ x: new Date(2016, 7), y: 4.9 },
			{ x: new Date(2016, 8), y: 4.9 },
			{ x: new Date(2016, 9), y: 4.8 },
			{ x: new Date(2016, 10), y: 4.6 },
			{ x: new Date(2016, 11), y: 4.7 },
			{ x: new Date(2017, 0), y: 4.8 },
			{ x: new Date(2017, 1), y: 4.7 },
			{ x: new Date(2017, 2), y: 4.5 },
			{ x: new Date(2017, 3), y:4.4 },
			{ x: new Date(2017, 4), y:4.3 },
			{ x: new Date(2017, 5), y:4.4 }
		]
	}]
});
chart.render();
}